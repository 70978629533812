const enum Language {
  ES_ES = "es",
  EN = "en",
}
const DEFAULT_LANGUAGE: Language = Language.ES_ES;

export { DEFAULT_LANGUAGE, Language };

export const Languages: { title: string; value: Language }[] = [
  { title: "Español", value: Language.ES_ES },
  { title: "Inglés", value: Language.EN },
];
interface ServiceLanguageMap {
  [key: string]: { name: string; slug?: string };
}

export const ServiceLanguages: ServiceLanguageMap = {
  [Language.ES_ES]: { name: "Servicios" },
  [Language.EN]: { name: "Services" },
};

export const ProjectLanguages: ServiceLanguageMap = {
  [Language.ES_ES]: { name: "Proyectos", slug: "proyectos" },
  [Language.EN]: { name: "Projects", slug: "projects" },
};

export const ContactLanguages: ServiceLanguageMap = {
  [Language.ES_ES]: { name: "Contacto", slug: "contacto" },
  [Language.EN]: { name: "Contact", slug: "contact" },
};
